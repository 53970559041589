@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "maplibre-gl/dist/maplibre-gl.css";

.cdk-virtual-scroll-content-wrapper {
  display: flex;
  position: relative !important;
}
@media (min-width: 1280px) {
  .cdk-virtual-scroll-content-wrapper {
    display: block;
  }
}

ion-toolbar .title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  img {
    max-height: 56px;
  }
}

.map-viz {
  position: fixed;
}

.map-treks-viz,
.map-introduction-viz {
  width: 100%;
  left: 0px;
  top: calc(56px + var(--ion-safe-area-top, 0));
  height: calc(100% - 56px - var(--ion-safe-area-top, 0));
}

.options-button-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 5;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  ion-spinner {
    align-self: center;
    width: 32px;
    height: 32px;
  }
}

.progress-modal {
  background-color: transparent;
  &::part(content) {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }
  .ion-page {
    justify-content: center;
    ion-card {
      background-color: white;
    }
  }
}

.pulse,
.pulse-and-view {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--ion-color-pulse);
  box-shadow: 0 0 0 var(--ion-color-pulse);
  animation: pulse 1s infinite;
  transform-style: preserve-3d;
}

.pulse-and-view:after {
  content: "";
  position: absolute;
  width: 48px;
  height: 48px;
  top: -48px;
  left: -16px;
  background-color: rgba(var(--ion-color-pulse-rgb), 0.7);
  transform: rotate(45deg) translateZ(-1px);
  border-top-left-radius: 100%;
  border: 2px solid var(--ion-color-pulse);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--ion-color-pulse-rgb), 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(var(--ion-color-pulse-rgb), 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(var(--ion-color-pulse-rgb), 0);
  }
}

.legend-text {
  color: var(--ion-color-medium);
  font-style: italic;
  font-size: 14px;
}

.trek-description {
  ol {
    margin-top: 0px;
    counter-reset: referencePoint;
    li {
      list-style-type: none;
      counter-increment: referencePoint;
      p {
        margin: 0;
      }
      &:not(:first-child) {
        margin-top: 12px;
      }
    }
    li:before {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      content: counter(referencePoint);
      width: 24px;
      height: 24px;
      background: #f04141;
      border-radius: 12px;
      font-weight: bold;
      font-size: 0.8em;
      color: white;
      margin-left: -36px;
    }
  }
}

.maplibregl-popup-content {
  width: 100%;
  cursor: pointer;
  padding: 0px;
  border: 1px solid var(--ion-color-secondary);
  ion-text {
    padding: 0px var(--ion-padding) 0px var(--ion-padding);
    width: calc(100% - 64px);
    h5 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.maplibregl-popup-tip {
  display: none;
}
.maplibregl-popup {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  background-color: #ffffff;
  max-height: 64px;
  pointer-events: auto;
  img {
    min-width: 64px;
    min-height: 64px;
    max-width: 64px;
    max-height: 64px;
    object-fit: cover;
  }
  h5 {
    text-align: center;
  }
}

.alert-wrapper.sc-ion-alert-md {
  width: 50vw;
}

@media (max-width: 540px) {
  .alert-wrapper.sc-ion-alert-md {
    width: 100%;
    margin: var(--ion-margin);
  }
}

.sc-ion-alert-md-h {
  --max-width: none;
}

.alert-radio-label.sc-ion-alert-md {
  white-space: pre-wrap;
}

.end-of-page {
  width: 100%;
  height: var(--ion-margin);
  margin-top: var(--ion-margin);
  background-color: var(--ion-color-secondary);
}
